import axios from 'axios'
import { Dialog } from 'vant'
import { getToken } from "../utils/cookie"
import store from '@/store'

axios.interceptors.request.use(
	config => {
		let admin_token = store.state.user.token || getToken();
		if (admin_token) {
			// 首次请求，由后台生成，前端可以保存
			// 让每个请求携带自定义token
			config.headers["token"] = admin_token
		}
		return config
	},
	error => Promise.reject(error)
)

axios.interceptors.response.use(
	response => {
		if (response.data.code !== 0 && response.data.code !== 200) {
			// 处理不成功状态
			Dialog.alert({
				message: response.data.msg || '系统繁忙，请稍后再试',
				theme: 'round-button',
				confirmButtonColor: '#fca142',
				width: 290,
			}).then(() => {
				// on close
				if (response.data.code === 401) {
					// 未登录
					const appNo = sessionStorage.getItem('appno') || 'APP01';
					localStorage.removeItem('loginInfor_' + appNo)
					location.href = "/#/";
				}
			});
		}
		return response
	},
	error => {
		if (error.message.includes('Network') && error.config.url.includes('compareFaceBase64')) {
			console.log('暂不执行此错误，延后调试')
		} else {
			Dialog.alert({
				message: error.message || '系统繁忙，请稍后再试',
				theme: 'round-button',
				confirmButtonColor: '#fca142',
				width: 290,
			});
		}
		console.log(`error message ${error}`); // for debug
		return Promise.reject(error);
	}
)

export const axiosGet = (url, params) => {
	return new Promise((resolve, reject) => {
		axios.get(url, { params })
			.then(res => resolve(res.data))
			.catch(err => reject(err))
	})
}

export const axiosPost = (url, params) => {
	return new Promise((resolve, reject) => {
		axios.post(url, params)
			.then(res => resolve(res.data))
			.catch(err => reject(err))
	})
}
