/**
 * (工具类) 封装有关cookie的方法
 * ++ 增删查token值;
 * ++ 增查头像、用户名等;
 * ++ 增查userid
 */

import Cookie from "js-cookie";

const tokenKey = "ADMIN-TOKEN";
// const in60Minutes = 1 / 24;

export function getToken() {
  return Cookie.get(tokenKey);
}
export function setToken(value) {
  return Cookie.set(tokenKey, value, {
    // expires: in60Minutes
  });
}
export function removeToken() {
  return Cookie.remove(tokenKey);
}

export function getName() {
  return Cookie.get("Name");
}
export function setName(value) {
  return Cookie.set("Name", value);
}

export function getAvatar() {
  return Cookie.get("Avatar");
}
export function setAvatar(value) {
  return Cookie.set("Avatar", value);
}

export function getUserId() {
  return Cookie.get("UserId");
}
export function setUserId(value) {
  return Cookie.set("UserId", value);
}

export function getMobile() {
  return Cookie.get("Mobile");
}
export function setMobile(value) {
  return Cookie.set("Mobile", value);
}

export function getCourseId() {
  return Cookie.get("CourseId");
}
export function setCourseId(value) {
  return Cookie.set("CourseId", value);
}

export function getCategoryId() {
  return Cookie.get("CategoryId");
}
export function setCategoryId(value) {
  return Cookie.set("CategoryId", value);
}

export function getCategoryName() {
  return Cookie.get("CategoryName");
}
export function setCategoryName(value) {
	return Cookie.set("CategoryName", value);
}

export function getPlanId() {
	return Cookie.get('planId')
}
export function setPlanId(value) {
	return Cookie.set('planId', value);
}

export function getCityCode() {
	return Cookie.get('cityCode')
}
export function setCityCode(value) {
	return Cookie.set('cityCode', value);
}

export function getEnterpriseId() {
	return Cookie.get('enterpriseId')
}
export function setEnterpriseId(value) {
	return Cookie.set('enterpriseId', value);
}

export function getVipTabIndex() {
	return Cookie.get('vipTabIndex')
}
export function setVipTabIndex(value) {
	return Cookie.set('vipTabIndex', value);
}

export function getLoginIdCard() {
	return Cookie.get('loginIdCard')
}
export function setLoginIdCard(value) {
	return Cookie.set('loginIdCard', value);
}
export function removeLoginIdCard() {
	return Cookie.remove('loginIdCard');
}

export function getLoginPwd() {
	return Cookie.get('loginPwd')
}
export function setLoginPwd(value) {
	return Cookie.set('loginPwd', value);
}
export function removeLoginPwd() {
	return Cookie.remove('loginPwd');
}

export function getLoginMobile() {
	return Cookie.get('loginUserMobile')
}
export function setLoginMobile(value) {
	return Cookie.set('loginUserMobile', value);
}
export function removeLoginMobile() {
	return Cookie.remove('loginUserMobile');
}
