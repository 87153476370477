import Vue from 'vue'
import router from "@/router/index";
import App from './App.vue'
import store from "@/store/index";
import Comp from './components/index'
import './css/animate.css'
import utils from './utils/utils'
import './utils/remScale.js'

// import { videoPlayer } from "vue-video-player";
// import "video.js/dist/video-js.css";
// import "vue-video-player/src/custom-theme.css";
// Vue.use(videoPlayer)
// import Video from 'video.js'
// import 'video.js/dist/video-js.css'
// Vue.prototype.$video = Video //引入Video播放器
// import "./permission";

import less from 'less';
Vue.use(less)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import cnzzAnalytics from 'vue-cnzz-analytics'
Vue.use(cnzzAnalytics, {
	router: router,
	siteIdList: [
		1280834376
	],
	isDebug: false
});

import { Dialog,NavBar,Notify,Tab,Tabs,Uploader,Checkbox,CheckboxGroup } from 'vant';

Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(NavBar);
Vue.use(Notify);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(Viewer, {
	defaultOptions: {
		zIndex: 9999,
		title:false,//显示当前图片的标题
		scalable:false,//图片是否可翻转
		rotatable:true,//图片是否可旋转
		tooltip:false,//显示缩放百分比
		navbar:false,//显示缩略图导航
		loop:false,
		loading:false
	}
})

/*** 重绘 less 和 $api ***/
const query = window.location.href.indexOf("study-center")===-1 ? window.location.href.split('?')[1] : "";
const appno = sessionStorage.getItem('appno')
if ( query != undefined && (query || appno)) {
// if (query || appno) {
	if (query.includes('=APP02') || appno === 'APP02') {
		require(`./less/app02.less`)
	} else if (query.includes('=APP03') || appno === 'APP03') {
		import(`./less/app03.less`)
	} else {
		import(`./less/app01.less`)
	}
}

import $api from './http/api'
/*** 重绘 less 和 $api ***/

Vue.use(Dialog);
Vue.prototype.$dialog = Dialog;
Vue.prototype.$api = $api

// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant);

// vant components
import {VANT_COMPOENTS} from './vant'

VANT_COMPOENTS.forEach(element => {
	Vue.use(element)
});

// 全局过滤器
Vue.filter('transToMin', function (value) {
	if (!value) return '';
	let minute = Math.floor(value / 60);
	let second = value % 60;
	minute = minute < 10 ? '0' + minute : minute;
	second = second < 10 ? '0' + second : second;
	return minute + ':' + second;
})
Vue.filter('getLetter', function (idx) {
	const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	return letters.charAt(idx);
})

Comp(Vue)
Vue.config.productionTip = false
Vue.prototype.$utils = utils
Vue.config.ignoredElements = ["wx-open-launch-weapp"]
new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		console.log('window.location ====>', window.location.href)
	}
}).$mount('#app')
