import {axiosGet, axiosPost} from './axios'
import qs from "qs";
import { wipeBase64Rrefix } from "../utils";

export default {
	getVideoList (data) {
		return axiosPost('/renren-fast/app/review/platform/select', data)
	},
	watchVideo (data) {
		return axiosPost('/renren-fast/app/student/procWatchHistory', data)
	},
	wxCompareFace (data) {
		return axiosPost('/renren-fast/app/student/compareFaceWxMp', qs.stringify(data))
	},
	compareFace (data) {
		data.photo = wipeBase64Rrefix(data.photo);
		return axiosPost('/renren-fast/app/student/compareFaceBase64', qs.stringify(data))
	},
	signOut (id, imgurl, mediaId, type) {
		return axiosPost(
			'/renren-fast/app/student/studentsignrecord/signout/' + id,
			qs.stringify({
				signPic: wipeBase64Rrefix(imgurl),
				mediaId,
				type
			})
		)
	},
	signOutNoPhoto (id,type) {
		return axiosPost(
			'/renren-fast/app/student/studentsignrecord/signOutNoPhoto/' + id,
			qs.stringify({
				type
			})
		)
	},
	// 获取学时
	changePlan(id) {
		return axiosPost(
			'/renren-fast/app/student/changePlan/' + id,
		)
	},

	checkSignOut (id) {
		return axiosGet('/renren-fast/app/student/studentsignrecord/canBeSignOut/' + id)
	},
	getNoSignOut (data) {
		return axiosPost('/renren-fast/app/student/studentsignrecord/getNoSignOut', data)
	},
	// 微信环境授权
	wxConfig () {
		const url = encodeURIComponent(window.location.protocol + '//' + window.location.hostname + '/index.html' + window.location.search)
		// const url = 'https://dev.h5.xunmeizaixian.com/index.html'
		return axiosGet('/renren-fast/wx/mp/portal/getSignature?url=' + url)
	}
}
