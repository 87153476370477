import { Dialog } from 'vant'
import store from '../store'

export class Utils {
	dialog = {
		alert (msg, callback = null, confirmButtonText = '确定') {
			Dialog.alert({
				title: '温馨提示',
				message: msg,
				theme: 'round-button',
				confirmButtonColor: store.state.app.theme.colorMain,
				confirmButtonText,
				width: 290,
			}).then(() => {
				if (typeof callback === 'function') {
					callback()
				}
			})
		},
		confirm (msg, callback = null, oncancel = null) {
			Dialog.confirm({
				title: '温馨提示',
				message: msg,
				theme: 'round-button',
				confirmButtonColor: store.state.app.theme.colorMain,
				cancelButtonColor: '#D4D4D4',
				width: 290,
			}).then(() => {
				if (typeof callback === 'function') {
					callback()
				}
			}).catch(() => {
				if (typeof oncancel === 'function') {
					oncancel()
				}
			})
		}
	}
	isString (val) { return typeof val === 'string' }
	isArray (val) {
		if ((typeof Array.isArray === 'function')) {
			return Array.isArray(val)
		} else {
			return Object.prototype.toString.call(val) === '[object Array]'
		}
	}

	required (val) {
		if (this.isArray(val)) {
			return Boolean(val.length)
		}
		if (this.isString(val)) {
			val = val.replace( /(^\s*)|(\s*$)/g, '')
			return Boolean(val)
		}
		return false
	}

	min (val, num) {
		if (this.isArray(val) || this.isString(val)) {
			return val.length >= num
		}
		return false
	}

	max (val, num) {
		if (this.isArray(val) || this.isString(val)) {
			return val.length <= num
		}
		return false
	}

	count (val, num) {
		if (this.isArray(val) || this.isString(val)) {
			return val.length === num
		}
		return false
	}
}


export default new Utils()
