export default {
	namespaced: true,
	state: {
		single: null, // 单点登录信息，没有则为null
		screen: {
			isAndroid: false,
			isIos: false,
			wx: false
		},
		face: { // 拍照记录
			signInPhoto: '', // 签到相片
			signBackPhoto: '' // 签退相片
		},
		subject: { // 科目计划信息
			curSubjectIndex: 0
		},
		checkStudent: [], //checkStudent返回学员数据
	},
	mutations: {
		set_single (state, val = null) {
			if (!val) {
				const single = sessionStorage.getItem('single')
				if (single) { val = single }
			}
			if (val) sessionStorage.setItem('single', JSON.stringify(val))
			state.single = val
		},
		set_screen (state, val) {
			const { key, value } = val
			state.screen[key] = value
		},
		set_face (state, val) {
			console.log('val info', val)
			const { key, value } = val
			state.face[key] = value
		},
		set_subject (state, val) {
			const { key, value } = val
			state.subject[key] = value
		},
		set_checkStudent(state, val) {
			state.checkStudent = val.checkStudent
		}
	},
	actions: {}
}
