/**
 * 封装（第一层封装，设置请求通用配置）的axios请求 （/src/api/uses.js等 调用）
 * ++ 设置baseURL;
 * ++ 结合vuex的store,请求头加入token;
 * ++ 拦截请求和返回,对返回结果做统一处理;
 */
import axios from "axios";
import { getToken } from "./cookie";
import store from "@/store/index";
import {
	Dialog
} from "vant";

const service = axios.create({
	timeout: 60000,
	timeoutErrorMessage: '连接超时，请稍后重试',
});

/* 请求拦截 */
const APIFilters = [
	'/api/login/byIdCard',
	'/api/login/byMobile',
	'/api/login/sendSmsCode',
	'/api/login/checkSmsCode',
	'/api/login/shanDongByIdCard',
	'/api/login/checkCode'
]
service.interceptors.request.use(config => {
	if (config.url === '/api/article/APP01/1/1') {
		config.url = config.url || ''
	} else if (
		config.url.includes('/wx/mp/portal/getSignature') ||
		config.url.includes('/wx/mp/buildWxMpConnectUrlLocal')
	) {
		config.url = '/renren-fast' + config.url;
	} else if (!APIFilters.includes(config.url)) {
		config.url = '/renren-fast/app' + config.url;
	}
	let admin_token = store.state.user.token || getToken();
	if (admin_token) {
		//首次请求，由后台生成，前端可以保存
		config.headers["token"] = admin_token; // 让每个请求携带自定义token
	}
	return config;
}, error => {
	Dialog.alert({
		message: error.message || '系统繁忙，请稍后再试',
		theme: 'round-button',
		confirmButtonColor: '#fca142',
		width: 290,
	});
	console.log(`error message ${error}`);
	Promise.reject(error);
});

/* 返回拦截 */
service.interceptors.response.use(response => {
	const res = response.data;
	if (res.code !== 0 && res.code !== 200 && !(response.config.url.includes('/student/processPayStatus') && res.code === 2009)) {
		// 处理不成功状态
		Dialog.alert({
			message: res.msg || '系统繁忙，请稍后再试',
			theme: 'round-button',
			confirmButtonColor: '#fca142',
			width: 290,
		}).then(() => {
			// on close
			if (res.code === 401) {
				// 未登录
				const appNo = sessionStorage.getItem('appno') || 'APP01';
				localStorage.removeItem('loginInfor_' + appNo)
				location.href = "/#/";
			}
		});
		return Promise.reject("error");
	} else {
		return APIFilters.includes(response.config.url) ? res : res.data || res.list;
	}
}, error => {
	if (error.message.includes('Network') && error.config.url.includes('compareFaceBase64')) {
		console.log('暂不执行此错误，延后调试')
	} else {
		Dialog.alert({
			message: error.message || '系统繁忙，请稍后再试',
			theme: 'round-button',
			confirmButtonColor: '#fca142',
			width: 290,
		});
	}
	console.log(`error message ${error}`); // for debug
	Promise.reject(error);
});

export default service;
