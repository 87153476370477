/**
 * 封装（第三层封装，进行vuex-store、cookie的数据操作，直接页面中调用）vuex-modules(user模块)（对应/src/api的user模块）
 * ++ 发起请求，接受保存数据
 * ++ 保存token值到cookie和vuex-store;
 * ++ 保存头像、用户名信息到到cookie和vuex-store;
 */
import {
	// 操作cookie的相关方法
	getToken,
	setToken,
	removeToken,
	getName,
	setName,
	getAvatar,
	setAvatar,
	getUserId,
	setUserId,
	getMobile,
	setMobile,
	getCourseId,
	setCourseId,
	getCategoryId,
	setCategoryId,
	getCategoryName,
	setCategoryName,
	setPlanId,
	getPlanId,
	setCityCode,
	getCityCode,
	setEnterpriseId,
	getEnterpriseId
} from "@/utils/cookie";
import {login, singleLogin, getInfo, logout} from "@/api/user"; // axios请求封装的第二层--请求方法（封装好请求参数，请求方法等）

const user = {
	state: {
		token: getToken(), // 首次请求，由后台生成，前端可以保存
		name: getName(),
		avatar: getAvatar(),
		userid: getUserId(),
		mobile: getMobile(),
		categoryid: getCategoryId(),
		categoryname: getCategoryName(),
		courseid: getCourseId(),
		roles: [],
		paystatus: '', // 支付状态
		planid: getPlanId(),
		cityCode: getCityCode(),
		openid: '',
		enterpriseId: getEnterpriseId(),
	},
	mutations: {
		// 变更状态（同步）
		set_token: (state, token) => {
			state.token = token;
		},
		set_name: (state, name) => {
			state.name = name;
		},
		set_avatar: (state, avatar) => {
			state.avatar = avatar;
		},
		set_roles: (state, roles) => {
			state.roles = roles;
		},
		set_userid: (state, userid) => {
			state.userid = userid;
		},
		set_mobile: (state, mobile) => {
			state.mobile = mobile;
		},
		set_courseid: (state, courseid) => {
			state.courseid = courseid;
		},
		set_categoryid: (state, categoryid) => {
			state.categoryid = categoryid;
		},
		set_categoryname: (state, categoryname) => {
			state.categoryname = categoryname;
		},
		set_planid: (state, planid) => {
			state.planid = planid;
		},
		set_cityCode: (state, val) => {
			state.cityCode = val;
		},
		set_paystatus: (state, paystatus) => {
			state.paystatus = paystatus;
		},
		set_enterpriseId: (state, val) => {
			state.enterpriseId = val;
		}
	},
	actions: {
		// 异步,调用mutations的变更状态的方法
		// 请求的第三层封装（在页面中调用）
		Login({commit}, userInfo) {
			return new Promise((resolve, reject) => {
				login(userInfo)
					.then(response => {
						console.log("Login登录成功：", response);
						// studentPic studentName basePkId certNum mobile
						let data = response.data[0];
						setToken(response.token);
						setName(data.studentName);
						setAvatar(data.studentPic);
						setUserId(data.basePkId);
						setMobile(data.mobile);
						setCategoryId(data.categoryId);
						setPlanId(data.planId)
						setCityCode(data.cityCode)
						setEnterpriseId(data.enterpriseId)
						commit('set_planid', data.planId);
						commit('set_cityCode', data.cityCode);
						commit("set_token", response.token);
						commit("set_name", data.studentName);
						commit("set_avatar", data.studentPic);
						commit("set_userid", data.basePkId);
						commit("set_mobile", data.mobile);
						commit('set_categoryid', data.categoryId);
						commit('set_paystatus', data.studentPayStatus);
						commit('set_enterpriseId', data.enterpriseId);
						resolve(response);
					})
					.catch(error => {
						console.log(`error message: ${error}`);
						reject(error);
					});
			});
		},
		SingleLogin({commit}, userInfo) {
			return new Promise((resolve, reject) => {
				singleLogin(userInfo)
					.then(response => {
						console.log("SingleLogin登录成功：", response);
						// studentPic studentName basePkId certNum mobile
						let data = response.data[0];
						setToken(response.token);
						setName(data.studentName);
						setAvatar(data.studentPic);
						setUserId(data.basePkId);
						setMobile(data.mobile);
						setCategoryId(data.categoryId);
						setPlanId(data.planId)
						setCityCode(data.cityCode)
						setEnterpriseId(data.enterpriseId)
						commit('set_planid', data.planId);
						commit('set_cityCode', data.cityCode);
						commit("set_token", response.token);
						commit("set_name", data.studentName);
						commit("set_avatar", data.studentPic);
						commit("set_userid", data.basePkId);
						commit("set_mobile", data.mobile);
						commit('set_categoryid', data.categoryId);
						commit('set_paystatus', data.studentPayStatus);
						commit('set_enterpriseId', data.enterpriseId);
						resolve(response);
					})
					.catch(error => {
						console.log(`error message: ${error}`);
						reject(error);
					});
			});
		},
		GetInfo({commit, state}) {
			return new Promise((resolve, reject) => {
				getInfo(state.token)
					.then(response => {
						let data = response.data;
						setName(data.name);
						setAvatar(data.avatar);
						commit("set_name", data.name);
						commit("set_avatar", data.avatar);
						resolve(response);
					})
					.catch(error => {
						console.log(`error message: ${error}`);
						reject(error);
					});
			});
		},
		// 登出
		LogOut({commit, state}) {
			return new Promise((resolve, reject) => {
				logout(state.token)
					.then(() => {
						commit("set_token", "");
						removeToken();
						resolve();
					})
					.catch(error => {
						console.log(`error message: ${error}`);
						reject(error);
					});
			});
		},
		// 前端 登出
		FrontEndLogOut({commit}) {
			return new Promise(resolve => {
				commit("set_token", "");
				removeToken();
				resolve();
			});
		},
		// courseid
		SetCourseId({commit}, id) {
			setCourseId(id);
			commit('set_courseid', id);
		},
		// 类别
		SetCategory({commit}, info) {
			setCategoryId(info.categoryId);
			setCategoryName(info.categoryName);
			commit('set_categoryid', info.categoryId);
			commit('set_categoryname', info.categoryName);
		}
	}
};

export default user;
