export function isExternalPath(path) {
	return /^(https?:|mailto:|tel:)/.test(path);
}

export function transToMin(sec) {
	let minute = Math.floor(sec / 60);
	let second = sec%60;
	minute = minute<10?'0'+minute:minute;
	second = second<10?'0'+second:second;
	return minute+':'+second;
}

export function transToMinWithUnit(sec) {
	let minute = Math.floor(sec/60);
	let second = sec%60;
	minute = minute<10?'0'+minute:minute;
	second = second<10?'0'+second:second;
	return minute+'分'+second+'秒';
}

export const wipeBase64Rrefix = (base64) => {
	if (base64.startsWith('data:image/png;base64,')) {
		return base64.replace(/data:image\/png;base64,/, '');
	}
	if (base64.startsWith('data:image/jpeg;base64,')) {
		return base64.replace(/data:image\/jpeg;base64,/, '');
	}
	return base64
}
