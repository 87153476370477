/**
 * 封装vuex （main.js调用）
 * ++ 使用vuex-store作为存储仓库，储存app的状态，进行统一管理;
 * ++ 包含vuex-modules,vuex-getters,(vuex-state,vuex-mutations,vuex-actions)
 */
import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import app from "./modules/app";
import cache from './modules/cache'

Vue.use(Vuex);

const store = new Vuex.Store({
  // Vuex 允许我们将 store 分割成模块（module）。
  // 每个模块拥有自己的 state、mutations、actions、getters、甚至是嵌套子模块
  modules: {
    user,
    app,
    cache
  },
  getters: {
    /*  state中派生的属性
     * Vuex 允许我们在 store 中定义“getter”（可以认为是 store 的计算属性,用于计算 store中的附加属性）。
     * 就像计算属性一样，getter 的返回值会根据它的依赖被缓存起来，且只有当它的依赖值发生了改变才会被重新计算。
     * Getter 接受 state 作为其第一个参数,Getter 也可以接受其他 getter 作为第二个参数：
     */
    token: state => state.user.token,
    name: state => state.user.name,
    avatar: state => state.user.avatar,
    roles: state => state.user.roles
  }
});

export default store;
