<template>
	<div id="app" :class="appno">
		<!-- <img class="logo" alt="Vue logo" src="./assets/logo.png"> -->
		<!-- <login msg="Welcome to Your Vue.js App"/> -->
		<!-- <div class="status-padding" :style="{height: navHeight+'px',background:'linear-gradient(90deg,'+colorSecond+','+colorMain+')'}"></div> -->
		<!--        <keep-alive include="videocomp">-->
		<router-view></router-view>
		<!--        </keep-alive>-->
		<!-- <div class="footer-padding" :style="{height: tabbarHeight+'px'}"></div> -->
	</div>
</template>

<script>
// import login from './views/login.vue'
import {setToken} from "@/utils/cookie";

export default {
	name: "App",
	data() {
		return {
			// navHeight  tabbarHeight
			navHeight: 0,
			tabbarHeight: 0,
			token: "",
			appno: "",
			colorMain: "#ff8506",
			colorSecond: "#fca142",
		};
	},
	components: {
		// login,
	},
	beforeDestroy() {
		sessionStorage.clear();
		localStorage.clear();
	},
	created() {
		// console.log(window.curCity)
		this.$store.commit('cache/set_single') // 刷新的时候获取 单点登录信息
	},
	mounted() {
		this.getUrlParams();
		this.loadScreen();
		this.checkVersion();
	},
	methods: {
		loadScreen() {
			const screen = navigator.userAgent;
			const isAndroid = screen.indexOf('Android') > -1 || screen.indexOf('Adr') > -1;
			const isIos = !!screen.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			this.$store.commit('cache/set_screen', {key: 'isAndroid', value: isAndroid})
			this.$store.commit('cache/set_screen', {key: 'isIos', value: isIos})
			this.$store.commit('cache/set_screen', {
				key: 'wx',
				value: screen.toLowerCase().match(/MicroMessenger/i) ? screen.toLowerCase().match(/MicroMessenger/i)[0] === 'micromessenger' : false
			})
		},
		getUrlParams() {
			// http://ip-address:8084/#/?navHeight=88&tabbarHeight=83&token=eyJh
			// 	fun getTimerH5Url(
			// 		appNo: String = "APP02",
			// 		categoryId: String = "",
			// 		subject: String = "",
			// 		navHeight: Int? = null,
			// 		tabbarHeight: Int? = null
			// ): String {
			// 		return "${getH5Urls()?.timingUrl}?token=${SpUtil.decodeString("h5Token")}&appNo=${appNo}&categoryId=${categoryId}&subject=${subject}&navHeight=${navHeight}&tabbarHeight=${tabbarHeight}"
			// 	}
			let params = window.location.hash.split("?")[1];
			let singleParams = [];
			if (params) singleParams = params.split("&");
			if (singleParams && singleParams.length) {
				singleParams.map((v) => {
					let value = v.split("=")[1];
					if (v.indexOf("navHeight") != -1) {
						this.navHeight = value;
						sessionStorage.setItem("navHeight", this.navHeight);
					}
					if (v.indexOf("tabbarHeight") != -1) {
						this.tabbarHeight = value;
						sessionStorage.setItem("tabbarHeight", this.tabbarHeight);
					}
					if (v.indexOf("appNo") != -1 || v.indexOf("appno") != -1) {
						this.appno = value;
						sessionStorage.setItem("appno", this.appno);
						let no = this.appno == "APP01" ? "1" : this.appno == "APP02" ? "2" : "3";
						this.$store.dispatch("setAppno", no);

						switch (this.appno) {
							case "APP02":
								this.colorMain = "#2db1ac";
								this.colorSecond = "#96cdfa";
								break;
							case "APP03":
								this.colorMain = "#1175fe";
								this.colorSecond = "#b1ffee";
								break;
							case "APP01":
								this.colorMain = "#ff8506";
								this.colorSecond = "#fca142";
								break;
							case "APP04":
								this.colorMain = "#F56C6C";
								this.colorSecond = "#48d89c";
								break;	
							case "APP05":
								this.colorMain = "#E6A23C";
								this.colorSecond = "#48d89c";
								break;
							case "APP06":
								this.colorMain = "#67C23A";
								this.colorSecond = "#48d89c";
								break;
							case "APP09":
								this.colorMain = "#6A5ACD";
								this.colorSecond = "#6A5ACD";
								break;	
							case "APP10":
								this.colorMain = "#6A5ACD";
								this.colorSecond = "#6A5ACD";
							break;	
							default:
								this.colorMain = "#ff8506";
								this.colorSecond = "#fca142";
								break;
						}

						this.$store.commit('set_theme', { key: 'colorMain', val: this.colorMain })
						this.$store.commit('set_theme', { key: 'colorSecond', val: this.colorSecond })
					}
					if (v.indexOf("token") != -1) {
						this.token = value;
						setToken(this.token);
						sessionStorage.setItem("token", this.token);
						// 登录 获取信息
					}
				});
				// if (this.token) {
				// 	this.toLogin({appNo: this.appno || "APP01", token: this.token});
				// } else {
				// 	this.$router.replace(this.$store.state.cache.screen.wx ? '/login' : '/auth')
				// }
			}
			// else {
			// 	this.$router.replace(this.$store.state.cache.screen.wx ? '/login' : '/auth')
			// }
		},
		toLogin(data) {
			this.$store.dispatch("Login", data).then(() => {
					// this.$store
					// .dispatch("GetInfo")
					// .then(() => {
					if (this.$route.name === 'auth' || this.$route.name === 'index') {
						this.$router.push({path: "/train"});
					} else {
						this.$router.replace(this.$store.state.cache.screen.wx ? '/login' : '/auth')
					}
					// })
				}).catch(() => {
					// this.$message.error(err);
				});
		},
		checkVersion() {
			this.$nextTick(() => {
				(function() {
					let appDom = document.getElementById('app')
					let checkDom = document.createElement('span')
					checkDom.setAttribute('id', 'xm-check-version-dom')
					checkDom.style.display = 'none'
					appDom.appendChild(checkDom)
					//console.log('check version created.');
				})()
			});
		},
	},
};
</script>

<style lang="less">
@colorMain: #ff8506;
@colorSecond: #fca142;

// global style
.bg_primary{ background-color: @colorMain !important; }
.bg_primary_second{ background-color: @colorSecond !important; }
.text_primary { color: @colorMain !important; }
.text_primary_second { color: @colorSecond !important; }
.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
.border { border: 1px solid @colorMain; }
.btn{
	&.plain{
		background-color: transparent;
		border: 1px solid @colorMain;
		color: @colorMain;
	}
}
.la-btn{
	&.plain{
		background-color: transparent;
		border: 1px solid @colorMain;
		color: @colorMain;
	}
	&.round {
		border-radius: 30px;
	}
}

* {
	box-sizing: border-box;
}

body {
	max-width: 600px;
	margin: auto;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333333;
	font-size: 14px;
	/* padding: 20px 0 40px; */
}

.page-container {
	position: relative;
	// padding-top: env(safe-area-inset-top);
	// padding-top: const(safe-area-inset-top);
}

img.logo {
	width: 60px;
}

.c-333 {
	color: #666;
}

.c-666 {
	color: #666;
}

.c-999 {
	color: #999;
}

.c-main {
	color: @colorMain;
}

.c-white {
	color: #ffffff;
}

.s-12 {
	font-size: 12px;
}

.s-14 {
	font-size: 14px;
}

.s-15 {
	font-size: 15px;
}

.s-16 {
	font-size: 16px;
}

.s-17 {
	font-size: 17px;
}

.s-18 {
	font-size: 18px;
}

a {
	color: @colorMain;
}

a:active {
	color: @colorSecond;
}

.text-center {
	text-align: center;
}

h4 {
	margin: 0;
}

.bold {
	font-weight: 600;
}

.header {
	width: 100%;
	padding-bottom: 14px;
	background: linear-gradient(90deg, @colorMain, @colorMain);
}

.btn {
	max-width: 100%;
	width: 80%;
	margin: auto;
	height: 40px;
	font-size: 17px;
	color: #fff;
	background: linear-gradient(90deg, @colorSecond, @colorMain);
	border-radius: 40px;
	border: 1px solid @colorMain;
	font-weight: 600;
	text-align: center;
	line-height: 40px;
}

.btn.disable {
	opacity: 0.6;
	pointer-events: none;
}

.btn.default {
	background: #ffffff;
	color: @colorMain;
	border: 1px solid @colorMain;
}

.tips {
	padding: 4px 12px;
	font-size: 12px;
}

.title {
	position: relative;
}

.title.line {
	padding-left: 10px;
}

.title.line::before {
	content: "";
	width: 4px;
	height: 14px;
	background: @colorMain;
	border-radius: 4px;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -7px;
}

/* 更换颜色 APP02 */
.APP02 {
	@colorMain: #2db1ac;
	@colorSecond: #48d8a0;

	// global style
	.bg_primary{ background-color: @colorMain !important; }
	.bg_primary_second{ background-color: @colorSecond !important; }
	.text_primary { color: @colorMain !important; }
	.text_primary_second { color: @colorSecond !important; }
	.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
	.border { border: 1px solid @colorMain; }
	.la-btn{
		&.plain{
			background-color: transparent;
			border: 1px solid @colorMain;
			color: @colorMain;
		}
		&.round {
			border-radius: 30px;
		}
	}

	.c-main {
		color: @colorMain;
	}

	a {
		color: @colorMain;
	}

	a:active {
		color: @colorSecond;
	}

	.header {
		width: 100%;
		padding-bottom: 14px;
		background: linear-gradient(90deg, @colorMain, @colorMain);
	}

	.btn {
		background: linear-gradient(90deg, @colorSecond, @colorMain);
		border: 1px solid @colorMain;
	}

	.btn.default {
		background: #ffffff;
		color: @colorMain;
		border: 1px solid @colorMain;
	}

	.title {
		&.line::before {
			background: @colorMain;
		}
	}

	& ~ .van-dialog {
		.van-dialog__confirm,
		.van-dialog__confirm:active {
			color: #fff;
			background: @colorMain !important;
			border: 1px solid @colorMain !important;
		}
	}
}

/* 更换颜色 APP03 */
.APP03 {
	@colorMain: #1175fe;
	@colorSecond: #3fadfa;

	// global style
	.bg_primary{ background-color: @colorMain !important; }
	.bg_primary_second{ background-color: @colorSecond !important; }
	.text_primary { color: @colorMain !important; }
	.text_primary_second { color: @colorSecond !important; }
	.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
	.border { border: 1px solid @colorMain; }
	.la-btn{
		&.plain{
			background-color: transparent;
			border: 1px solid @colorMain;
			color: @colorMain;
		}
		&.round {
			border-radius: 30px;
		}
	}

	.c-main {
		color: @colorMain;
	}

	a {
		color: @colorMain;
	}

	a:active {
		color: @colorSecond;
	}

	.header {
		width: 100%;
		padding-bottom: 14px;
		background: linear-gradient(90deg, @colorMain, @colorMain);
	}

	.btn {
		background: linear-gradient(90deg, @colorSecond, @colorMain);
		border: 1px solid @colorMain;
	}

	.btn.default {
		background: #ffffff;
		color: @colorMain;
		border: 1px solid @colorMain;
	}

	.title {
		&.line::before {
			background: @colorMain;
		}
	}

	& ~ .van-dialog {
		.van-dialog__confirm,
		.van-dialog__confirm:active {
			color: #fff;
			background: @colorMain !important;
			border: 1px solid @colorMain !important;
		}
	}
}

/* 更换颜色 APP04 */
.APP04 {
	@colorMain: #F56C6C;
	@colorSecond: #f5876c;

	// global style
	.bg_primary{ background-color: @colorMain !important; }
	.bg_primary_second{ background-color: @colorSecond !important; }
	.text_primary { color: @colorMain !important; }
	.text_primary_second { color: @colorSecond !important; }
	.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
	.border { border: 1px solid @colorMain; }
	.la-btn{
		&.plain{
			background-color: transparent;
			border: 1px solid @colorMain;
			color: @colorMain;
		}
		&.round {
			border-radius: 30px;
		}
	}

	.c-main {
		color: @colorMain;
	}

	a {
		color: @colorMain;
	}

	a:active {
		color: @colorSecond;
	}

	.header {
		width: 100%;
		padding-bottom: 14px;
		background: linear-gradient(90deg, @colorMain, @colorMain);
	}

	.btn {
		background: linear-gradient(90deg, @colorSecond, @colorMain);
		border: 1px solid @colorMain;
	}

	.btn.default {
		background: #ffffff;
		color: @colorMain;
		border: 1px solid @colorMain;
	}

	.title {
		&.line::before {
			background: @colorMain;
		}
	}

	& ~ .van-dialog {
		.van-dialog__confirm,
		.van-dialog__confirm:active {
			color: #fff;
			background: @colorMain !important;
			border: 1px solid @colorMain !important;
		}
	}
}

/* 更换颜色 APP05 */
.APP05 {
	@colorMain: #E6A23C;
	@colorSecond: #d8b648;

	// global style
	.bg_primary{ background-color: @colorMain !important; }
	.bg_primary_second{ background-color: @colorSecond !important; }
	.text_primary { color: @colorMain !important; }
	.text_primary_second { color: @colorSecond !important; }
	.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
	.border { border: 1px solid @colorMain; }
	.la-btn{
		&.plain{
			background-color: transparent;
			border: 1px solid @colorMain;
			color: @colorMain;
		}
		&.round {
			border-radius: 30px;
		}
	}

	.c-main {
		color: @colorMain;
	}

	a {
		color: @colorMain;
	}

	a:active {
		color: @colorSecond;
	}

	.header {
		width: 100%;
		padding-bottom: 14px;
		background: linear-gradient(90deg, @colorMain, @colorMain);
	}

	.btn {
		background: linear-gradient(90deg, @colorSecond, @colorMain);
		border: 1px solid @colorMain;
	}

	.btn.default {
		background: #ffffff;
		color: @colorMain;
		border: 1px solid @colorMain;
	}

	.title {
		&.line::before {
			background: @colorMain;
		}
	}

	& ~ .van-dialog {
		.van-dialog__confirm,
		.van-dialog__confirm:active {
			color: #fff;
			background: @colorMain !important;
			border: 1px solid @colorMain !important;
		}
	}
}

/* 更换颜色 APP06 */
.APP06 {
	@colorMain: #67C23A;
	@colorSecond: #48d89c;

	// global style
	.bg_primary{ background-color: @colorMain !important; }
	.bg_primary_second{ background-color: @colorSecond !important; }
	.text_primary { color: @colorMain !important; }
	.text_primary_second { color: @colorSecond !important; }
	.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
	.border { border: 1px solid @colorMain; }
	.la-btn{
		&.plain{
			background-color: transparent;
			border: 1px solid @colorMain;
			color: @colorMain;
		}
		&.round {
			border-radius: 30px;
		}
	}

	.c-main {
		color: @colorMain;
	}

	a {
		color: @colorMain;
	}

	a:active {
		color: @colorSecond;
	}

	.header {
		width: 100%;
		padding-bottom: 14px;
		background: linear-gradient(90deg, @colorMain, @colorMain);
	}

	.btn {
		background: linear-gradient(90deg, @colorSecond, @colorMain);
		border: 1px solid @colorMain;
	}

	.btn.default {
		background: #ffffff;
		color: @colorMain;
		border: 1px solid @colorMain;
	}

	.title {
		&.line::before {
			background: @colorMain;
		}
	}

	& ~ .van-dialog {
		.van-dialog__confirm,
		.van-dialog__confirm:active {
			color: #fff;
			background: @colorMain !important;
			border: 1px solid @colorMain !important;
		}
	}
}

/* 更换颜色 APP09 */
.APP09 {
	@colorMain: #6A5ACD;
	@colorSecond: #9e48d8;

	// global style
	.bg_primary{ background-color: @colorMain !important; }
	.bg_primary_second{ background-color: @colorSecond !important; }
	.text_primary { color: @colorMain !important; }
	.text_primary_second { color: @colorSecond !important; }
	.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
	.border { border: 1px solid @colorMain; }
	.la-btn{
		&.plain{
			background-color: transparent;
			border: 1px solid @colorMain;
			color: @colorMain;
		}
		&.round {
			border-radius: 30px;
		}
	}

	.c-main {
		color: @colorMain;
	}

	a {
		color: @colorMain;
	}

	a:active {
		color: @colorSecond;
	}

	.header {
		width: 100%;
		padding-bottom: 14px;
		background: linear-gradient(90deg, @colorMain, @colorMain);
	}

	.btn {
		background: linear-gradient(90deg, @colorSecond, @colorMain);
		border: 1px solid @colorMain;
	}

	.btn.default {
		background: #ffffff;
		color: @colorMain;
		border: 1px solid @colorMain;
	}

	.title {
		&.line::before {
			background: @colorMain;
		}
	}

	& ~ .van-dialog {
		.van-dialog__confirm,
		.van-dialog__confirm:active {
			color: #fff;
			background: @colorMain !important;
			border: 1px solid @colorMain !important;
		}
	}
}
/* 更换颜色 APP10 */
.APP10 {
	@colorMain: #6A5ACD;
	@colorSecond: #9e48d8;

	// global style
	.bg_primary{ background-color: @colorMain !important; }
	.bg_primary_second{ background-color: @colorSecond !important; }
	.text_primary { color: @colorMain !important; }
	.text_primary_second { color: @colorSecond !important; }
	.gradient { background-image: linear-gradient(to left, @colorMain, @colorMain) !important; }
	.border { border: 1px solid @colorMain; }
	.la-btn{
		&.plain{
			background-color: transparent;
			border: 1px solid @colorMain;
			color: @colorMain;
		}
		&.round {
			border-radius: 30px;
		}
	}

	.c-main {
		color: @colorMain;
	}

	a {
		color: @colorMain;
	}

	a:active {
		color: @colorSecond;
	}

	.header {
		width: 100%;
		padding-bottom: 14px;
		background: linear-gradient(90deg, @colorMain, @colorMain);
	}

	.btn {
		background: linear-gradient(90deg, @colorSecond, @colorMain);
		border: 1px solid @colorMain;
	}

	.btn.default {
		background: #ffffff;
		color: @colorMain;
		border: 1px solid @colorMain;
	}

	.title {
		&.line::before {
			background: @colorMain;
		}
	}

	& ~ .van-dialog {
		.van-dialog__confirm,
		.van-dialog__confirm:active {
			color: #fff;
			background: @colorMain !important;
			border: 1px solid @colorMain !important;
		}
	}
}


/*  */
.van-field__left-icon {
	margin-right: 8px !important;
}
</style>
