import {
	Button,
	Form,
	Field,
	Icon,
	Tabs,
	Tab,
	Tag,
	Circle,
	Popover,
	Popup,
	Rate,
	Empty,
	List,
	Search,
	IndexBar,
	IndexAnchor,
	Cell,
	CellGroup,
	Loading,
	Overlay,
	ActionSheet,
	NavBar,
	Cascader,
	NoticeBar,
	RadioGroup,
	Radio,
	Grid,
	GridItem,
	Picker,
	Uploader,
	Badge,
	AddressList,
	Col,
	Row
} from 'vant';

export const VANT_COMPOENTS = [
	Button,
	Form,
	Field,
	Icon,
	Tabs,
	Tab,
	Tag,
	Circle,
	Popover,
	Popup,
	Rate,
	Empty,
	List,
	Search,
	IndexBar,
	IndexAnchor,
	Cell,
	CellGroup,
	Loading,
	Overlay,
	ActionSheet,
	NavBar,
	Cascader,
	NoticeBar,
	RadioGroup,
	Radio,
	Grid,
	GridItem,
	Picker,
	Uploader,
	Badge,
	AddressList,
	Col,
	Row
]
