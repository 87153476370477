import Cookies from "js-cookie";

const store = {
	state: {
		sidebar: 1,
		appno: Cookies.get("appNo") || '1',
		theme: {
			colorMain: '#ff8506',
			colorSecond: '#fca142'
		}
	},
	mutations: {
		set_theme (state, data) {
			const { key, val } = data
			state.theme[key] = val
		},
		open_sidebar: (state, flag) => {
			state.sidebar = flag;
			// Cookies.set("openFlag", flag);
		},
		close_sidebar: (state, flag) => {
			state.sidebar = flag;
			// Cookies.set("openFlag", flag);
		},
		set_appno: (state, no) => {
			state.appno = no;
			Cookies.set("appNo", no);
		},
		clear_appno: (state) => {
			state.appno = '';
			Cookies.remove("appNo");
		},
	},
	actions: {
		openSidebar: ({commit}, flag) => {
			commit("open_sidebar", flag);
		},
		closeSidebar: ({commit}, flag) => {
			commit("close_sidebar", flag);
		},
		setAppno: ({commit}, no) => {
			commit("set_appno", no);
		},
		clearAppno: ({commit}) => {
			commit("clear_appno");
		}
	}
};

export default store;
