<template>
  <van-dialog
    v-model="show"
    :title="title"
    show-cancel-button
    confirm-button-color="#FF8506"
  >
    <p class="message">满意请给五星哦~~</p>
    <div class="rate" v-if="title === '课程评价'">
      <Field name="rate" label="课程内容" label-align="center">
        <template #input>
          <rate v-model="value1" icon="like" void-icon="like-o" color="#FF8506" />
        </template>
      </Field>
      <Field name="rate" label="教学方法" label-align="center">
        <template #input>
          <rate v-model="value2" icon="like" void-icon="like-o" color="#FF8506" />
        </template>
      </Field>
      <Field name="rate" label="教学效果" label-align="center">
        <template #input>
          <rate v-model="value3" icon="like" void-icon="like-o" color="#FF8506" />
        </template>
      </Field>
    </div>
    <div class="rate" v-if="title === '服务评价'">
      <Field name="rate" label="服务态度" label-align="center">
        <template #input>
          <rate v-model="value4" icon="like" void-icon="like-o" color="#FF8506" />
        </template>
      </Field>
      <Field name="rate" label="服务能力" label-align="center">
        <template #input>
          <rate v-model="value5" icon="like" void-icon="like-o" color="#FF8506" />
        </template>
      </Field>
      <Field name="rate" label="服务效率" label-align="center">
        <template #input>
          <rate v-model="value6" icon="like" void-icon="like-o" color="#FF8506" />
        </template>
      </Field>
    </div>
  </van-dialog>
</template>

<script>
import { Dialog, Rate, Field } from 'vant';
export default {
  name: "cEvaluate",
  components: { [Dialog.Component.name]: Dialog.Component, Rate, Field },
  props: ['show', 'title'],
  data: () => ({
    value1: 0,
    value2: 0,
    value3: 0,
    value4: 0,
    value5: 0,
    value6: 0
  })
}
</script>

<style scoped>
  .message{ text-align: center; font-size: 14px; }
  .rate {
    text-align: center;
    width: 262px;
    margin: 0 auto;
  }
</style>
